export default {
	stage: 'stg',
	studentApiBaseUrl: 'https://studentapi.tst.prtl.co',
	hubUrl: 'https://studentsystem.tst.prtl.co/studentjs/dist/storageHub.html',
	serviceLayerBaseUrl: 'https://reflectorjwt.stg.prtl.co',
	wishlistServiceBaseUrl: 'https://decisionmakingapi.tst.prtl.co',
	tokenServiceBaseUrl: 'https://tokens.tst.prtl.co',
	wssEndpoint: 'wss://studentwebsocketservice.tst.prtl.co/v1',
	allowedOrigins: [
	  /.*/
	]
};
